import React from "react"
import Layout from "../components/layout/layout"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import JotformEmbed from "react-jotform-embed"
import { graphql, useStaticQuery } from "gatsby"
import { IoIosMail } from "react-icons/io"
import { RiPhoneFill, RiMapPin2Fill } from "react-icons/ri"
import { FaFax } from "react-icons/fa"
import CommitmentSignature from "../components/sections/CommitmentSignature"
import useWindowSize from "../utils/useWindowSize"
import SEOnew from "../components/seo-new"
const Contact2Page = () => {
  const data = useStaticQuery(graphql`
    query ContactPageSectionQuery {
      prismicContactPage {
        data {
          title
          hero_image {
            gatsbyImageData(width: 1920)
            alt
          }
          company_name
          address
          google_map_link
          phone_number
          fax_number
          email
          office_image {
            gatsbyImageData(width: 589)
            alt
          }
        }
      }
    }
  `)

  const doc = data.prismicContactPage
  const heroImage = getImage(doc.data.hero_image)
  const image = getImage(doc.data.office_image)
  const screen = useWindowSize()

  return (
    <Layout>
      <SEOnew title={doc.data.title} />
      <div className="w-full bg-site-gray flex flex-col lg:flex-row overflow-hidden rounded-none lg:rounded-bl-100 h-64 lg:h-300 relative">
        <div className="w-full lg:w-1/2 h-full relative">
          <GatsbyImage image={heroImage} alt={heroImage.alt} />
          <div className="absolute top-0 right-0 lg:-right-1 w-full h-full bg-toTopGray lg:bg-toRightGray bg-transparent">
            &nbsp;
          </div>
        </div>
        <div className="absolute lg:static w-full flex flex-col  items-center justify-end lg:hidden pb-8 h-full ">
          <span className="text-white text-center text-4xl tracking-widest ">
            Contact Us
          </span>
          <div className="flex w-full justify-between px-4">
            <div className="flex items-center space-x-3 mt-3">
              <a
                href={`tel:${doc.data.phone_number}`}
                className="text-base"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiPhoneFill
                  className="w-4 h-4 text-white hover:text-site-yellow"
                  aria-label="telephone"
                />
              </a>

              <a
                href={`tel:${doc.data.phone_number}`}
                className="text-base text-white hover:underline"
              >
                {doc.data.phone_number}
              </a>
            </div>
            <div className="flex items-center space-x-3 mt-3">
              <a
                href={`mailto:${doc.data.email}`}
                className="text-base"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoIosMail
                  className="w-4 h-4 text-white hover:text-site-yellow"
                  aria-label="email"
                />
              </a>
              <a
                href={`mailto:${doc.data.email}`}
                className="text-base text-white hover:underline"
              >
                {doc.data.email}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center flex-col mb-16 ">
        <div className="w-full max-w-7xl bg-transparent flex flex-col-reverse lg:flex-row relative px-4 pt-4 lg:pt-16">
          <div className="w-full lg:w-1/2 mt-12 lg:mt-0">
            <div className="mt-4 mx-4 lg:min-h-contactLocation">
              <h2 className="text-2xl lg:text-3xl text-black font-extrabold border-b-2 border-gray-300 pb-3">
                Location
              </h2>
              <div className="flex items-center space-x-3 mt-3">
                <a
                  href={doc.data.google_map_link}
                  className="text-lg font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiMapPin2Fill
                    className="w-6 h-6 text-site-gray hover:text-site-yellow"
                    aria-label="Location"
                  />
                </a>
                <a
                  href={doc.data.google_map_link}
                  className="text-xl font-medium text-site-gray hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {doc.data.address}
                </a>
              </div>

              <div className="w-full flex items-center justify-center mx-auto mt-6 mb-4 lg:mb-16">
                <a
                  href={doc.data.google_map_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-2 border-site-gray hover:border-site-yellow"
                >
                  <GatsbyImage image={image} alt={image.alt} />
                </a>
              </div>
            </div>
          </div>
          <div
            className="w-full lg:w-1/2 py-4 lg:px-2 rounded-md lg:absolute "
            style={{
              top: "-250px",
              right: "0px",
            }}
          >
            <div className="w-full hidden lg:flex flex-col items-center justify-center pb-8">
              <span className="text-white text-center text-4xl tracking-widest">
                Contact Us
              </span>
              <div className="flex w-full justify-center px-4 gap-x-12 pt-4">
                <div className="flex items-center space-x-3 mt-3">
                  <a
                    href={`tel:${doc.data.phone_number}`}
                    className="text-base"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiPhoneFill
                      className="w-6 h-6 text-white hover:text-site-yellow"
                      aria-label="telephone"
                    />
                  </a>

                  <a
                    href={`tel:${doc.data.phone_number}`}
                    className="text-lg text-white hover:underline"
                  >
                    {doc.data.phone_number}
                  </a>
                </div>
                <div className="flex items-center space-x-3 mt-3">
                  <a
                    href={`mailto:${doc.data.email}`}
                    className="text-base"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoIosMail
                      className="w-6 h-6 text-white hover:text-site-yellow"
                      aria-label="email"
                    />
                  </a>
                  <a
                    href={`mailto:${doc.data.email}`}
                    className="text-lg text-white hover:underline"
                  >
                    {doc.data.email}
                  </a>
                </div>
              </div>
            </div>
            <div className="bg-white p-1 rounded-lg shadow-2xl">
              <JotformEmbed
                src={
                  doc.data.jotform_link
                    ? doc.data.jotform_link
                    : "https://form.jotform.com/232334327126449"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <CommitmentSignature />
    </Layout>
  )
}

export default Contact2Page
